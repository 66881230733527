export default{
    domain:'app.loongapps.cn',
    protocol:'https',
    prefixApp:'/api/app/',
    prefixFtp:'/api/file/',
    requestHeader:{
        "Source-Type":"web",
    },
    // port:8080,
    // ftpPort:8090,
    getBaseUrl(){
        return this.protocol+"://"+this.domain+ "/api/app"
    },
    getFTPUrl(){
        return this.protocol+"://"+this.domain+"/api/file"
    },
    getLogoImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/logo1.png'
    },
    getNotFoundImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/404.png'
    },
    getBuildingImg(){
        return this.getFTPUrl()+'/upload/indeximg/index1.svg'
    },
    getBigLogoImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/bigLogo.png'
    },
    getShareImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/share.jpg'
    },
    getFooterImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/footer.jpg'
    },
    getBannerImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/banner.jpg'
    },
    getManImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/contact/man.jpg'
    },
    getLocationImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/contact/biao1.jpg'
    },
    getTelephoneImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/contact/biao2.jpg'
    },
    getEmailImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/contact/biao3.jpg'
    },
    getRedLogoImg(){
        return this.getFTPUrl()+'/upload/webpicture/images/redlogo.png'
    }
}