<template>
  <div class="middle">
      <svg width="48" height="48" viewBox="0 0 48 48">
        <image id="矢量智能对象" width="48" height="48" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADbElEQVRogeWaPWgUQRiGn6wYjNiE0SLoIiwoFjaKoFEiEo0REVLZSKzsxDpoIIikEFMH7CJIjI1N0CYXsAn+QWxEISAsyCIiuqQRg4pG5vz23Nvbndu927276Nvd3s3M8978fd/MdpGTfNvpBvoABfQCPUC31P4dWANW9U+BD8pz9bOm1ZQB33ZsYBQYAQ4Bm1IW/QksA/PArPJcr1GGhgz4tnMUmACGm/0TgHVgAZhUnvs0a+FMjfu2sweYBk5nbSilSsAV5blvczXg244FjAE3QuO6KOm5cR2YUp77q2kDvu1sB+aAoYLBo1oELijP/Wz6kdGATFLdrfuKJDVoRQ9X0yRPNCDwz4CdLYaO6j3Qn2TCinsow6bUAfAIQ0mYalRjQCbsXBuHTZw0y5ywVSmuB8baMGHTaEjYqlQ1B2Sdf92CpbJR6SV2f3ifiPbAdAfDI2zT4QeVHpDw4ElbsLLrWBB2hHtgogPAZoCzwEXgleF3FdZyD8ia/y6HwKwZXVaeezso79vONuAlsDemTh0A7tZ7Q9ADo50Er6U894v0SJy6hLkyhEZaTRxSDXxIPwzlysxdkkl9zZCM5KlEeOHSoczBhPZ0UrTVkjSw0+A1zx0DPMLcZ0kO22rVg7+rQ+kUTMqSBDyN3gBLwLcmzeYFr9VryemBSXqsnVeeq7fw40AQbrQbXqvHShE6zCjPfRB8kLh8sAETecNrdcfmAxEtRx8oz/2U0UQR8GVZEuGZdCDuuwwmCoPX7JacmJl0ybedkw2aKBJea82S4z6TNgMPfdsZzGiiaHitVUvOKutJr1SPMphoBXy5uqyhhB5u55TnPk6A2wGcUp57P+H7POHLoUQQTj8HDqcsaDSRpJzhtV4ozz0SLKPzGQoah1OcCoAnYA4MzEqSkFapTRQEvy7MfwzI7rqQsZK6JgqC11oITurCO/FkAxUlmigQnjBrxYBk+aUGKgtMDAcPfNvZAtwrCL4UvgjJ+2BLH4l/BE4Au5pGrVXNwVZNIu/bzlXgZgGN56Fx5blVbHHR6JT8k50mzXQryhR7lCJH2UsddEKtLzoG4m5r/s0LDv7uDf3ivl1aMcEbDYRMDLRpTizKsDFegtdNKWXcnQGupcje8pBuY1y3We+Gkv/mojuqDfuqQVQb9mWPOLXldRvgN1AqZYwfNJOGAAAAAElFTkSuQmCC"/>
      </svg>
      <div class="title">
        扫码成功， <span class="num">{{num}}</span>s后自动跳转到首页
      </div>
      <div>
        <el-button type="primary" size="medium" class="one_btn" @click="$router.push('/home')">返回首页</el-button>
      </div>

  </div>
</template>

<script>
  import {getPersonal} from '@/utils/network'
  export default {
    data() {
      return {
        num:3,
        timerId:null,
        token:''
      }
    },
    async destroyed() {
      const {data: res} = await  getPersonal({})
      if (res.code !== 200) return localStorage.removeItem('token')
      await  this.$store.dispatch('setToken', {
        token: this.token,
      })
      await  this.$store.dispatch('gerUserInfo', {
        user: res.data
      })

      clearInterval(this.timerId)

    },

    mounted() {
      //localStorage.setItem('token', this.$route.query.token)
      this.token = this.$route.query.token
      localStorage.setItem('token', this.token);

      this.go()
      this.getTime()

    },
    methods: {
      go() {
        setTimeout(() => {
          this.$router.push('/home')
        }, 3000)
      },
      getTime() {
         this.timerId = setInterval(() => {
          this.num --

        }, 1000)
      }
    },
    components: {}
  }
</script>

<style lang="less" scoped>
  .middle {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    margin: 200px auto;
    .title {
      margin: 46px 0;
      font-size: 14px;
      color: #797979;
      .num {
        color: #000;
        font-size: 20px;
        margin-right: 3px;
      }
    }

  }

</style>
